<template>
  <b-card no-body class="rounded-0 border border-info">
    <b-card-header class="bg-white d-flex justify-content-between align-items-center">
      <h6 class="mt-2 font-weight-bold">{{ variant.variant_adi[defaultDil].baslik }}</h6>
      <div class="d-flex">
        <v-select
          v-model="variant.tipi"
          :options="variantTipi"
          :reduce="(tip) => tip.value"
          label="title"
          :clearable="false"
          class="invoice-filter-select d-block pr-2"
          style="min-width: 200px"
        />

        <v-select
          v-model="ozellik_adi"
          :options="variant.ozellik"
          label="baslik"
          :clearable="false"
          class="invoice-filter-select d-block pr-2"
          style="min-width: 200px"
          @input="handlerCreatedOzellik($event)"
        >
          <template slot="selected-option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
          <template slot="option" slot-scope="option">
            {{ option.icerik[defaultDil].baslik }}
          </template>
        </v-select>

        <b-button class="rounded-0" variant="outline-danger" @click="handlerRemoveVariant(index)">
          <i class="fad fa-trash" />
        </b-button>
      </div>
    </b-card-header>
    <b-card-body class="p-0 p-2" v-if="form.variant_k_no">
      <table class="table border table-striped">
        <thead class="font-weight-bold">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Seçenek</th>
            <th scope="col">Barkod</th>
            <th scope="col">Ozel Alan</th>
            <th scope="col">Stok</th>
            <th scope="col">{{ form.variant_fiyat_turu == 'net_fiyat' ? 'Net Fiyat' : 'Fiyat Farkı' }}</th>
            <th scope="col">Görsel</th>
            <th scope="col">İşlem</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in variant.secenekler" :key="index">
            <th class="text-center">
              <h6 class="mt-2">{{ index + 1 }}</h6>
            </th>
            <td>
              <b-form-input :disabled="true" v-model="variant.secenekler[index].icerik[defaultDil].baslik" class="rounded-0" />
            </td>
            <td>
              <b-form-input :disabled="variant.tipi == 'ozellik'" v-model="variant.secenekler[index].barkod" class="rounded-0" />
            </td>
            <td>
              <b-form-input
                :disabled="variant.tipi == 'ozellik'"
                v-model="variant.secenekler[index].ozel_alan"
                class="rounded-0"
              />
            </td>
            <td>
              <b-form-input
                :disabled="variant.tipi == 'ozellik'"
                type="number"
                v-model="variant.secenekler[index].stok"
                class="rounded-0 text-right"
              />
            </td>
            <td>
              <b-form-input
                :disabled="variant.tipi == 'ozellik'"
                v-model="variant.secenekler[index].fiyat"
                class="rounded-0 text-right"
              />
            </td>
            <td>
              <v-select
                v-model="variant.secenekler[index].gorsel"
                :options="form.gorseller"
                :reduce="(diller) => diller.k_no"
                label="baslik"
                :clearable="true"
                class="invoice-filter-select d-block"
                style="min-width: 200px"
                :disabled="variant.tipi == 'ozellik'"
              >
                <template slot="selected-option" slot-scope="option">
                  {{ option.gorsel }}
                </template>
                <template slot="option" slot-scope="option">
                  <b-img thumbnail width="150" :src="handlerImageShow(option.gorsel)" />
                </template>
              </v-select>
            </td>
            <td>
              <b-button class="rounded-0" variant="outline-danger" @click="handlerRemoveOzellik(index)">
                <i class="fad fa-trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { ref, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  components: { vSelect },
  props: {
    index: [Number],
    variant: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { form, variant } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.ozellik_adi = ref(null);

    expo.variantTipi = ref([
      {
        value: 'ozellik',
        title: 'Özellik Olarak Ekle',
      },
      {
        value: 'secenek',
        title: 'Seçenek Olarak Ekle',
      },
    ]);

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        const url = `${base_url}uploads/${fileName}/urun/${val}`;
        return url;
      }
    };

    expo.handlerRemoveVariant = (event) => {
      const select_var_k_no = form.value.variant_senecekleri[event].variant_k_no;
      form.value.variant_k_no.splice(form.value.variant_k_no.indexOf(select_var_k_no), 1);
      form.value.variant_senecekleri.splice(event, 1);
    };

    expo.handlerRemoveOzellik = (event) => {
      variant.value.secenekler.splice(event, 1);
    };

    expo.handlerCreatedOzellik = (event) => {
      const varmi = variant.value.secenekler.find((x) => x.k_no == event.k_no);
      if (!varmi) {
        variant.value.secenekler.push({
          k_no: event.k_no,
          icerik: event.icerik,
          barkod: null,
          ozel_alan: event.ozel_alan,
          stok: 0,
          fiyat_turu: 'net_fiyat',
          fiyat: 0,
          gorsel: null,
        });
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
