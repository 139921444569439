var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'tarih')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'sayfa-guncelle',
            params: { k_no: props.row.k_no },
          }}},[_vm._v(" "+_vm._s(_vm._f("momentFull")(props.row.save_date))+" ")])],1):(props.column.field === 'baslik')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'sayfa-guncelle',
            params: { k_no: props.row.k_no },
          }}},[_vm._v(" "+_vm._s(props.row.icerik[_vm.defaultDil].baslik)+" ")])],1):(props.column.field === 'dil' && _vm.diller.length > 1)?_c('span',_vm._l((props.row.icerik),function(dil,i){return _c('b-badge',{key:i,staticClass:"rounded-0 mr-1",attrs:{"variant":_vm.langColor(dil)}},[_vm._v(" "+_vm._s(i)+" ")])}),1):(props.column.field === 'kategori')?_c('span',[(props.row.kategori != null)?_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(props.row.kategori[_vm.defaultDil].baslik)+" ")]):_vm._e()],1):(props.column.field === 'save_user')?_c('span',[(props.row.save_user != '')?_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm._f("str_limit")(props.row.save_user,10))+" ")]):_vm._e(),(props.row.edit_user != '')?_c('b-badge',{staticClass:"rounded-0",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(_vm._f("str_limit")(props.row.edit_user,10))+" ")]):_vm._e()],1):(props.column.field === 'statu')?_c('span',[_c('b-form-checkbox',{staticClass:"custom-control-info",attrs:{"switch":"","size":"lg"},on:{"change":function($event){return _vm.handlerStatu(props.row.k_no, $event)}},model:{value:(props.row.statu),callback:function ($$v) {_vm.$set(props.row, "statu", $$v)},expression:"props.row.statu"}})],1):(props.column.field === 'anasayfa')?_c('span',[_c('b-form-checkbox',{staticClass:"custom-control-info",attrs:{"switch":"","size":"lg"},on:{"change":function($event){return _vm.handlerAnasayfa(props.row.k_no, $event)}},model:{value:(props.row.anasayfa),callback:function ($$v) {_vm.$set(props.row, "anasayfa", $$v)},expression:"props.row.anasayfa"}})],1):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-button-group',{staticClass:"pb-25",attrs:{"size":"sm"}},[_c('b-button',{staticClass:"rounded-0",attrs:{"variant":"warning","to":{
              name: 'sayfa-guncelle',
              params: { k_no: props.row.k_no },
            }}},[_c('i',{staticClass:"fad fa-edit"})]),_c('b-button',{staticClass:"rounded-0",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handlerRemove(props.row.k_no)}}},[_c('i',{staticClass:"fad fa-trash"})])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center my-3"},[_c('v-select',{staticClass:"invoice-filter-select",staticStyle:{"width":"200px"},attrs:{"options":_vm.perPageOptions,"clearable":false},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}})],1),_c('div',[_c('b-pagination',{staticClass:"my-3",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('i',{staticClass:"fad fa-chevron-left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"fad fa-chevron-right"})]},proxy:true}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }