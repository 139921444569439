<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white">
              <h6 class="mt-2">Fiyatlandırma</h6>
            </b-card-header>
            <b-card-body class="p-0 p-2">
              <b-row>
                <b-col cols="12">
                  <b-form-group label-cols="5" label="Kur Türü">
                    <b-form-input size="lg" class="rounded-0 text-right" ref="kur_turu" v-model="form.kur_turu" disabled />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-cols="5" label="Kdv Oranı">
                    <b-input-group>
                      <b-input-group-append>
                        <v-select
                          v-model="form.kdv_turu"
                          :options="kdvTuru"
                          :reduce="(kdv) => kdv.id"
                          label="title"
                          :clearable="false"
                          class="invoice-filter-select d-block select-size-lg"
                          style="min-width: 170px"
                        />
                      </b-input-group-append>
                      <b-form-input size="lg" class="rounded-0 text-right" ref="baslik" v-model="form.kdv_oran" />
                      <b-input-group-prepend is-text class="">
                        <span class="px-2" style="font-size: 16px"> % </span>
                      </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-cols="5" label="Alış Fiyatı">
                    <b-input-group>
                      <b-form-input size="lg" class="rounded-0 text-right" ref="baslik" v-model="form.alis_fiyat" />
                      <b-input-group-prepend is-text> {{ form.kur_turu }} </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-cols="5" label="Satış Fiyatı">
                    <b-input-group>
                      <b-form-input size="lg" class="rounded-0 text-right" ref="baslik" v-model="form.satis_fiyat" />
                      <b-input-group-prepend is-text> {{ form.kur_turu }} </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <span>Özel Satış Fiyat Tanımlama </span>
                    <b-button
                      size="sm"
                      class="rounded-0"
                      variant="outline-info"
                      @click="$emit('createOzelFiyat', true)"
                      v-b-tooltip.hover="'Üyelere Özel Fiyat Tanımlayabilirsiniz'"
                    >
                      Özel Fiyat Tanımla
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12" v-if="form.ozel_satis_fiyat">
                  <b-row v-for="(ozel, index) in form.ozel_satis_fiyat" :key="index">
                    <b-col cols="12" md="4">
                      <div class="d-flex justify-content-between pt-2">
                        <span>Satış Fiyatı </span>
                        <b-button size="sm" class="rounded-0" variant="outline-danger" @click="$emit('removeOzelFiyat', index)">
                          <i class="fad fa-trash"></i>
                        </b-button>
                      </div>
                    </b-col>
                    <b-col cols="12" md="8">
                      <b-form-group>
                        <b-input-group>
                          <template #prepend>
                            <v-select
                              v-model="form.ozel_satis_fiyat[index].uye_grup_k_no"
                              :options="uyeGruplari"
                              :reduce="(statu) => statu.k_no"
                              label="baslik"
                              :clearable="false"
                              class="invoice-filter-select d-block select-size-lg"
                              style="min-width: 170px"
                              @input="handlerSelectedUyeGrup(index, $event)"
                            />
                          </template>
                          <b-form-input
                            size="lg"
                            class="rounded-0 text-right"
                            ref="baslik"
                            v-model="form.ozel_satis_fiyat[index].oran"
                            disabled
                          />
                          <b-input-group-prepend is-text> % </b-input-group-prepend>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card no-body class="rounded-0">
            <b-card-header class="bg-white">
              <h6 class="mt-2">Stok & Kargo İşlemleri</h6>
            </b-card-header>
            <b-card-body class="p-0 p-2">
              <b-row>
                <b-col cols="12" v-if="stokBirimleri.length > 0">
                  <b-form-group label-cols="5" label="Stok Birim">
                    <v-select
                      v-model="form.stok_birim_k_no"
                      :options="stokBirimleri"
                      :reduce="(stok) => stok.k_no"
                      label="baslik"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-cols="5"
                    label="Stok Risk | Risk Adeti"
                    v-b-tooltip.hover="'Risk durumuna düştüğünde haber eder'"
                  >
                    <b-input-group>
                      <b-input-group-append is-text>
                        <b-form-checkbox class="ml-2" :checked="form.stok_risk_uyari" v-model="form.stok_risk_uyari" size="lg" />
                      </b-input-group-append>
                      <b-form-input
                        size="lg"
                        type="number"
                        class="rounded-0 text-right"
                        ref="baslik"
                        v-model="form.stok_risk_adet"
                        :disabled="!form.stok_risk_uyari"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-cols="5"
                    label="Minimum & Maksimum Satış"
                    v-b-tooltip.hover="'Müşteriler Minimum ve Maksimum Kaç Adet Satın Alabilirler'"
                  >
                    <b-input-group>
                      <b-input-group-append>
                        <b-form-input
                          size="lg"
                          type="number"
                          class="rounded-0 text-right"
                          ref="baslik"
                          v-model="form.minimum_satis"
                        />
                      </b-input-group-append>
                      <b-form-input
                        size="lg"
                        type="number"
                        class="rounded-0 text-right"
                        ref="baslik"
                        v-model="form.maximum_satis"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-3">
                    <label for="statu">Stok Takibi Yap</label>
                    <b-form-checkbox :checked="form.stok_takibi" v-model="form.stok_takibi" switch size="lg" />
                  </div>
                </b-col>
                <b-col cols="12">
                  <div class="d-flex justify-content-between align-items-center py-3">
                    <label for="statu">Ücretsiz Kargo</label>
                    <b-form-checkbox :checked="form.ucretsiz_kargo" v-model="form.ucretsiz_kargo" switch size="lg" />
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-cols="5"
                    label="Kargo Desi"
                    v-b-tooltip.hover="
                      'Kargo Desi Girildiği Zaman Hesaplama Kargo Firmasına Girilmiş Desi Fiyatına Göre Hesaplanır'
                    "
                  >
                    <b-form-input
                      size="lg"
                      type="number"
                      class="rounded-0 text-right"
                      ref="baslik"
                      v-model="form.kargo_desi"
                      :disabled="form.ucretsiz_kargo"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label-cols="5"
                    label="Kargo Teslimat Süresi"
                    v-b-tooltip.hover="'Kargo Ortalama Teslimat Süresi Gün Cinsinden giriniz'"
                  >
                    <b-form-input size="lg" type="number" class="rounded-0 text-right" ref="baslik" v-model="form.kargo_suresi" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="px-4">
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button size="lg" :variant="form.k_no == null ? 'success' : 'warning'" type="submit" block class="rounded-0 mr-1">
              <i class="fad fa-save" />
              {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import store from '@/store';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { form } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.uyeGruplari = computed(() => store.getters.getUyeGrupListele);
    expo.stokBirimleri = computed(() => store.getters.getStokBirimler);

    expo.kdvTuru = ref([
      { id: 'haric', title: 'Hariç' },
      { id: 'dahil', title: 'Dahil' },
    ]);

    expo.handlerSelectedUyeGrup = (index, event) => {
      const group = expo.uyeGruplari.value.find((x) => x.k_no === event);
      form.value.ozel_satis_fiyat[index].oran = group.oran;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('onSubmit', true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
