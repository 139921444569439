<template>
  <b-tabs class="p-2" lazy>
    <b-tab title="İÇERİK" active>
      <UrunIcerik
        :diller="diller"
        :form="form"
        @form="form = $event"
        @onSubmit="onSubmit"
      />
    </b-tab>
    <b-tab title="FOTOGRAĞLAR" :disabled="!form.k_no">
      <UrunFotograflar
        :form="form"
        @form="form = $event"
        @show="$emit('show', $event)"
      />
    </b-tab>
    <b-tab title="FİYAT & STOK & KARGO" :disabled="!form.k_no">
      <UrunFiyatStokKargo
        :form="form"
        @onSubmit="onSubmit"
        @createOzelFiyat="createOzelFiyat"
        @removeOzelFiyat="removeOzelFiyat"
      />
    </b-tab>
    <b-tab title="VARİANT & KAMPANYA" :disabled="!form.k_no">
      <UrunVariantKampanya
        :form="form"
        @onSubmit="onSubmit"
        @removeVariant="removeVariant"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import store from "@/store";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification/composition";
import UrunIcerik from "./component/UrunIcerik.vue";
import UrunFotograflar from "./component/UrunFotograflar.vue";
import UrunFiyatStokKargo from "./component/UrunFiyatStokKargo.vue";
import UrunVariantKampanya from "./component/UrunVariantKampanya.vue";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from "@vue/composition-api";
import { useRouter } from "@/libs/utils";
import { langs } from "@/libs/languages";
export default defineComponent({
  components: {
    UrunIcerik,
    UrunFotograflar,
    UrunFiyatStokKargo,
    UrunVariantKampanya,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    const { route } = useRouter();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    const routeChange = computed(() => route.value);

    expo.form = ref({
      k_no: null,
      kategori_k_no: [],
      marka_k_no: null,
      urun_kodu: null,
      barkod: null,
      icerik: {},
      video_url: null,
      gorseller: [],
      kur_turu: "TRY",
      kdv_turu: "haric", // Haric, Dahil
      kdv_oran: 0,
      alis_fiyat: 0,
      satis_fiyat: 0,
      ozel_satis_fiyat: [],
      stok_adet: 0,
      stok_birim_k_no: null,
      stok_risk_uyari: false,
      stok_risk_adet: 0,
      stok_takibi: true,
      minimum_satis: 0,
      maximum_satis: 0,
      ucretsiz_kargo: false,
      kargo_desi: 0,
      kargo_suresi: 0,
      variant_k_no: null,
      variant_senecekleri: [],
      varinat_fiyat_turu: "fiyat_fark",
      kampanya_k_no: null,
      ilgili_urunler: [],
      vitrin_gosterim: false,
      statu: "yayinda", //yayinda,taslak,yayin_disi
      ek_urun: false,
      seo: true,
    });

    const resetForm = () => {
      expo.form.value = {
        k_no: null,
        kategori_k_no: null,
        marka_k_no: null,
        urun_kodu: null,
        barkod: null,
        icerik: {},
        video_url: null,
        gorseller: [],
        kur_turu: "TRY",
        kdv_turu: 0,
        kdv_oran: 0,
        alis_fiyat: 0,
        satis_fiyat: 0,
        ozel_satis_fiyat: [],
        stok_adet: 0,
        stok_birim_k_no: null,
        stok_risk_uyari: false,
        stok_risk_adet: 0,
        stok_takibi: true,
        minimum_satis: 0,
        maximum_satis: 0,
        ucretsiz_kargo: false,
        kargo_desi: 0,
        kargo_suresi: 0,
        variant_k_no: null,
        variant_senecekleri: [],
        variant_fiyat_turu: "fiyat_fark",
        kampanya_k_no: null,
        ilgili_urunler: [],
        izleme: 0,
        vitrin_gosterim: [],
        statu: "yayinda", //yayinda,taslak,yayin_disi
        satis_durum: true,
        seo: true,
      };
    };

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: "",
          aciklama: "",
          aciklama_2: "",
          description: "",
          keyword: [],
          slug: null,
        };
      });
      expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;
    };

    createIcerikDilUret();

    expo.createOzelFiyat = () => {
      const uyeGrupLength = store.getters.getUyeGrupListele.length;
      const ozelFiyatLength = expo.form.value.ozel_satis_fiyat.length;
      if (ozelFiyatLength < uyeGrupLength) {
        expo.form.value.ozel_satis_fiyat.push({ uye_grup_k_no: null, oran: 0 });
      }
    };

    expo.removeOzelFiyat = (index) => {
      expo.form.value.ozel_satis_fiyat.splice(index, 1);
    };

    expo.removeVariant = (index) => {
      expo.form.value.variant_senecekleri.splice(index, 1);
    };

    onMounted(async () => {
      context.emit("show", true);
      await handlerUpdated();
      context.emit("show", false);
    });

    const handlerUpdated = async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        context.emit("pageTitle", "Ürün Güncelle");
        // const urunler = store.getters.getETicaretUrunler;
        // let urun = {};
        // if (urunler.length > 0) {
        //   urun = urunler.find((x) => x.k_no === k_no);
        //   expo.form.value = { ...urun };
        // } else {
        context.emit("show", true);
        await store.dispatch("eTicUrunFindOne", k_no).then((res) => {
          expo.form.value = { ...res };
          context.emit("show", false);
        });
        // }
        context.emit("backTo", true);
      } else {
        context.emit("pageTitle", "Ürün Ekle");
      }
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      const baseUrl =
        expo.form.value.k_no == null ? "eTicUrunEkle" : "eTicUrunGuncelle";
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            expo.form.value = res.data.data;
            toast.success(
              !expo.form.value.k_no ? "Ekleme Başarılı" : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            context.emit("show", false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    watch(routeChange, (val) => {
      if (Object.keys(val.params).length == 0) {
        resetForm();
        createIcerikDilUret();
      }
    });

    return { ...expo };
  },
  beforeRouteLeave(to, from, next) {
    if (this.form.k_no == null) {
      if (
        this.form.icerik[this.defaultDil].baslik.length > 0 ||
        this.form.icerik[this.defaultDil].aciklama.length > 0 ||
        this.form.icerik[this.defaultDil].description.length > 0 ||
        this.form.icerik[this.defaultDil].keyword.length > 0
      ) {
        Swal.fire({
          title: "Uyarı",
          text: "Kaydedilmemiş veri var. Çıkmak istediğinizden eminmisiniz ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then((result) => {
          if (result.isConfirmed == true) {
            next();
          } else {
            this.$emit("backTo", true);
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
</script>

<style lang="scss" scoped></style>
