<template>
  <div class="products">
    <div class="product-header" :class="{ active: filtered }">
      <UrunFiltrele
        :filter="filter"
        @filtered="handlerTemizle"
        @handlerFilter="handlerFilter"
      />
    </div>
    <div class="product-body">
      <b-alert
        v-if="!urunler.length"
        show
        variant="warning"
        class="text-center m-2"
      >
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <div v-else>
        <UrunList
          @refresh="handlerFetchAllData"
          v-if="itemView == 'list-view'"
          :urunler="urunler"
        />
        <UrunGrid v-else :urunler="urunler" />
        <UrunPagination
          :urunler="urunler"
          :count="count"
          :filter="filter"
          @handlerPageSizeChange="handlerPageSizeChange($event)"
          @handlerPageChange="handlerPageChange($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import UrunFiltrele from "./component/UrunFiltrele.vue";
import UrunList from "./component/UrunList.vue";
import UrunGrid from "./component/UrunGrid.vue";

import store from "@/store";
import UrunPagination from "./component/UrunPagination.vue";
import { useRouter } from "@/libs/utils";
export default defineComponent({
  components: { UrunFiltrele, UrunList, UrunGrid, UrunPagination },
  props: {
    filtered: [Boolean],
    itemView: [String],
  },
  setup(props, context) {
    const expo = {};

    const { router, route } = useRouter();

    expo.urunler = ref([]);
    expo.count = ref(0);
    expo.filter = ref({
      arama: null,
      kategori_k_no: null,
      marka_k_no: null,
      siralama: null,
      statu: null,
      vitrin_gosterim: [],
    });
    expo.handlerFetchAllData = async () => {
      context.emit("show", true);
      context.emit("pageTitle", "Ürünler");

      route.value.query.page = route.value.query.page
        ? parseInt(route.value.query.page)
        : 1;
      route.value.query.page_size = route.value.query.page_size
        ? parseInt(route.value.query.page_size)
        : 20;

      await store.dispatch("eTicUrunListele", route.value.query).then((res) => {
        expo.urunler.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit(
          "total",
          `${expo.count.value} kayıttan ${expo.urunler.value.length} `
        );
        context.emit("show", false);
        context.emit("filtered", false);
      });
    };
    expo.handlerFetchAllData();

    expo.handlerFilter = () => {
      let query = {};

      if (expo.filter.value.kategori_k_no.length > 0) {
        let selected = expo.filter.value.kategori_k_no.toString();
        query = { ...route.value.query, kategori_k_no: selected };
      } else {
        delete query.kategori_k_no;
      }
      if (expo.filter.value.marka_k_no.length > 0) {
        let selected = expo.filter.value.marka_k_no.toString();
        query = { ...route.value.query, marka_k_no: selected };
      } else {
        delete query.marka_k_no;
      }
      if (expo.filter.value.vitrin_gosterim.length > 0) {
        let selected = expo.filter.value.vitrin_gosterim.toString();
        query = { ...route.value.query, vitrin_gosterim: selected };
      } else {
        delete query.vitrin_gosterim;
      }
      if (expo.filter.value.siralama) {
        query = { ...route.value.query, siralama: expo.filter.value.siralama };
      } else {
        delete query.siralama;
      }
      if (expo.filter.value.urun_tipi) {
        query = {
          ...route.value.query,
          urun_tipi: expo.filter.value.urun_tipi,
        };
      } else {
        delete query.urun_tipi;
      }
      if (expo.filter.value.statu) {
        query = { ...route.value.query, statu: expo.filter.value.statu };
      } else {
        delete query.statu;
      }

      if (expo.filter.value.satis_durum != null) {
        query = {
          ...route.value.query,
          satis_durum: expo.filter.value.satis_durum,
        };
      } else {
        delete query.satis_durum;
      }

      if (expo.filter.value.arama) {
        query = { ...route.value.query, arama: expo.filter.value.arama };
      } else {
        delete query.arama;
      }

      router.push({
        query: { ...query },
      });
    };

    expo.handlerTemizle = () => {
      router.push({
        query: {},
      });

      expo.filter.value = {
        arama: null,
        kategori_k_no: null,
        marka_k_no: null,
        siralama: null,
        statu: null,
        vitrin_gosterim: [],
        page: 0,
        page_size: 20,
      };
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    watch(
      route,
      (val) => {
        if (val.hash != "#" && val.name == "urun-listele") {
          expo.filter.value = {
            arama: route.value.query.arama ? route.value.query.arama : null,
            kategori_k_no: route.value.query.kategori_k_no
              ? route.value.query.kategori_k_no.split(",")
              : [],
            marka_k_no: route.value.query.marka_k_no
              ? route.value.query.marka_k_no.split(",")
              : [],
            siralama: route.value.query.siralama
              ? route.value.query.siralama
              : null,
            statu: route.value.query.statu ? route.value.query.statu : null,
            satis_durum: route.value.query.satis_durum
              ? route.value.query.satis_durum
              : null,
            vitrin_gosterim: route.value.query.vitrin_gosterim
              ? route.value.query.vitrin_gosterim.split(",")
              : [],
          };
          expo.handlerFetchAllData();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  .product-header {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebe9f1;
    padding: 10px 5px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    z-index: 5;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
