<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="form">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="7">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12">
                <validation-provider
                  name="Başlık"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group label="Başlık">
                    <b-form-input
                      size="lg"
                      class="rounded-0"
                      ref="baslik"
                      v-model="form.icerik[defaultDil].baslik"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerBaslikChange"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider
                  name="Ön Açıklama (Description)"
                  :rules="{ required: true, max: 160 }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    :label="`Ön Açıklama (Description) ${onAciklamaLenght} / 160`"
                    description="Ön Açıklama 160 karakteri geçmemelidir"
                  >
                    <b-form-textarea
                      class="rounded-0"
                      ref="on_aciklama"
                      v-model="form.icerik[defaultDil].description"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      @input="handlerOnAcklamaChange"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Açıklama">
                  <tinymce
                    id="aciklama"
                    v-model="form.icerik[defaultDil].aciklama"
                    :other_options="{ height: 350 }"
                  ></tinymce>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Açıklama 2">
                  <tinymce
                    id="aciklama_2"
                    v-model="form.icerik[defaultDil].aciklama_2"
                    :other_options="{ height: 350 }"
                  ></tinymce>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group label="Anahtar Kelimeler (Keywords)">
                  <b-form-tags
                    size="lg"
                    v-model="form.icerik[defaultDil].keywords"
                    input-id="tags-basic"
                    class="rounded-0"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Slug (Seflink)">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="video"
                    v-model="form.icerik[defaultDil].slug"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Video">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="video"
                    v-model="form.video"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="5">
          <b-card class="rounded-0">
            <b-row>
              <b-col cols="12" v-show="diller.length != 1">
                <b-form-group label-cols="5" label="Dil">
                  <v-select
                    v-model="defaultDil"
                    :options="diller"
                    :reduce="(diller) => diller.lang"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    :disabled="!form._id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Ürün Kodu">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="baslik"
                    v-model="form.urun_kodu"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Barkod">
                  <b-form-input
                    size="lg"
                    class="rounded-0"
                    ref="baslik"
                    v-model="form.barkod"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="kategoriler.length > 0">
                <validation-provider
                  name="Kategoriler"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group label-cols="5" label="Kategoriler">
                    <v-select
                      v-model="form.kategori_k_no"
                      :options="kategoriler"
                      :reduce="(kategori) => kategori.k_no"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      multiple
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" v-if="markalar.length > 0">
                <validation-provider
                  name="Marka"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group label-cols="5" label="Marka">
                    <v-select
                      v-model="form.marka_k_no"
                      :options="markalar"
                      :reduce="(marka) => marka.k_no"
                      :clearable="false"
                      class="invoice-filter-select d-block select-size-lg"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    >
                      <template slot="selected-option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.icerik[defaultDil].baslik }}
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Yayın Durumu">
                  <v-select
                    v-model="form.statu"
                    :options="statuler"
                    :reduce="(statu) => statu.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-cols="5" label="Vitrin Gösterim">
                  <v-select
                    multiple
                    v-model="form.vitrin_gosterim"
                    :options="vitrin"
                    :reduce="(durum) => durum.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-cols="5"
                  :label="`Satışa ${form.satis_durum ? 'Açık' : 'Kapalı'}`"
                >
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.satis_durum"
                    v-model="form.satis_durum"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label-cols="5"
                  :label="`Ek ${form.ek_urun ? 'Ürün' : 'Ürün Değil'}`"
                >
                  <b-form-checkbox
                    class="float-right"
                    :checked="form.ek_urun"
                    v-model="form.ek_urun"
                    switch
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center py-3"
                >
                  <label for="statu" class="h6">Seo Optimize</label>
                  <b-form-checkbox
                    :checked="form.seo"
                    v-model="form.seo"
                    switch
                    size="lg"
                  />
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  size="lg"
                  :variant="form.k_no == null ? 'success' : 'warning'"
                  type="submit"
                  block
                  class="rounded-0 mr-1"
                >
                  <i class="fad fa-save" />
                  {{ form.k_no == null ? "KAYDET" : "GÜNCELLE" }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { computed, defineComponent, ref, toRefs } from "@vue/composition-api";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useToast } from "vue-toastification/composition";
import slugify from "slugify";
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    // kategoriler: {
    //   type: Array,
    //   required: true,
    // },
    // markalar: {
    //   type: Array,
    //   required: true,
    // },
    diller: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};

    const { form } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.onAciklamaLenght = ref(0);
    // expo.editor = ref(ClassicEditor);
    expo.kategoriler = computed(() => store.getters.getTumKategoriler);
    expo.markalar = computed(() => store.getters.getMarkalar);

    expo.statuler = ref([
      { id: "yayinda", title: "Yayında" },
      { id: "yayin_disi", title: "Yayın Dışı" },
      { id: "taslak", title: "Taslak" },
    ]);

    expo.vitrin = ref([
      { id: "vitrin_1", title: "Vitrin 1" },
      { id: "vitrin_2", title: "Vitrin 2" },
      { id: "yeni_urun", title: "Yeni Ürün" },
      { id: "firsat_urun", title: "Fırsat Ürünü" },
      { id: "cok_satan", title: "Çok Satan" },
      { id: "begenilen", title: "Beğenilen" },
      { id: "indirimde", title: "İndirimde" },
      { id: "ozel_urun", title: "Özel Ürün" },
    ]);

    expo.handlerOnAcklamaChange = (event) => {
      expo.onAciklamaLenght.value = event.length > 0 ? event.length : 0;
    };

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      form.value.icerik[expo.defaultDil.value].slug = slug;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("onSubmit", true);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
