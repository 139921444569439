<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="urunler"
      :line-numbers="false"
      :pagination-options="{
        enabled: false,
        perPage: 100,
        mode: 'pages',
      }"
      @on-selected-rows-change="selectionChanged"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'Satır Secildi.',
        clearSelectionText: 'Temizle',
      }"
      :search-options="{ enabled: false }"
      styleClass="vgt-table striped bordered"
    >
      <div slot="selected-row-actions">
        <b-button
          squared
          variant="info"
          v-b-tooltip.hover="'Toplu İşlem'"
          @click="handlerTopluGuncelle"
        >
          <i class="fad fa-th-list" />
        </b-button>
        <b-button
          squared
          variant="secondary"
          v-b-tooltip.hover="'Kopyala'"
          @click="handlerUrunKopyala"
        >
          <i class="fad fa-copy" />
        </b-button>
        <b-button
          squared
          variant="danger"
          v-b-tooltip.hover="'Toplu Sil'"
          @click="handlerTopluSil"
        >
          <i class="fad fa-trash" />
        </b-button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'baslik'">
          <router-link
            :to="{
              name: 'urun-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="font-weight-bold d-block text-dark">
              {{ props.row.icerik[defaultDil].baslik }}
            </span>
            <span class="d-block text-dark"
              >{{ props.row.urun_kodu }} |
              <span class="text-danger" v-if="props.row.marka">{{
                props.row.marka[defaultDil].baslik
              }}</span></span
            >

            <span class="d-flex">
              <span class="d-flex align-items-center">
                İzleme
                <b-badge variant="primary" class="rounded-0 ml-1">
                  {{ props.row.izleme }}
                </b-badge>
              </span>
              <!-- <span class="d-flex align-items-center pl-1">
                Puan <b-badge variant="warning" class="rounded-0 ml-1"> 10 </b-badge>
              </span> -->
              <span class="d-flex align-items-center pl-1">
                Yorum
                <b-badge variant="info" class="rounded-0 ml-1">
                  {{ props.row.toplam_yorum }}
                </b-badge>
              </span>
              <span class="d-flex align-items-center pl-1">
                Sipariş
                <b-badge variant="success" class="rounded-0 ml-1">
                  {{ props.row.cikis }}
                </b-badge>
              </span>
            </span>
          </router-link>
        </span>
        <span
          class="d-flex align-items-center"
          v-else-if="props.column.field === 'dil' && diller.length > 1"
        >
          <b-badge
            :variant="langColor(props.row.icerik, dil.lang)"
            class="rounded-0 mr-1"
            v-for="(dil, i) in diller"
            :key="i"
          >
            {{ dil.lang }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'kategori'">
          <b-badge
            variant="primary"
            class="rounded-0 font-weight-light mr-1"
            style="font-size: 14px"
            v-for="(kat, i) in props.row.kategori"
            :key="i"
          >
            {{ kat.icerik[defaultDil].baslik }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'fiyat'">
          <b-badge variant="danger" class="rounded-0">
            Kdv : {{ props.row.kdv_oran | cur }} %</b-badge
          >
          <b-badge variant="warning" class="rounded-0">
            Alış : {{ props.row.alis_fiyat | cur }}
            {{ props.row.kur_turu }}</b-badge
          >
          <b-badge variant="primary" class="rounded-0">
            Satış : {{ props.row.satis_fiyat | cur }} {{ props.row.kur_turu }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'stok'">
          <b-badge
            :variant="props.row.bakiye > 0 ? 'success' : 'danger'"
            class="rounded-0 font-weight-light"
            style="font-size: 14px"
          >
            {{ props.row.bakiye }}
            {{
              props.row.stok_birim && props.row.stok_birim[defaultDil].baslik
            }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-badge
            :variant="
              props.row.statu == 'yayinda'
                ? 'success'
                : props.row.statu == 'yayin_disi'
                ? 'danger'
                : 'warning'
            "
            class="rounded-0 d-block font-weight-light"
            style="font-size: 14px"
          >
            {{
              props.row.statu == "yayinda"
                ? "Yayında"
                : props.row.statu == "yayin_disi"
                ? "Yayın Dışı"
                : "Taslak"
            }}
          </b-badge>

          <b-badge
            class="mt-2 rounded-0 d-block"
            :variant="props.row.satis_durum ? 'success' : 'danger'"
          >
            {{ props.row.satis_durum ? "Satışa Açık" : "Satışa Kapalı" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'vitrin'">
          <span
            class="d-block font-weight-bold"
            style="font-size: 12px"
            v-for="(vit, index) in props.row.vitrin_gosterim"
            :key="index"
          >
            {{ vitrinText(vit) }}</span
          >
        </span>
        <span
          v-else-if="props.column.field === 'action'"
          class="float-right px-1"
        >
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="primary"
              v-b-tooltip.hover.top="'Ürün Hareketleri'"
              :to="{
                name: 'urun-hareketler',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="info"
              v-b-tooltip.hover.top="'Açılış Kartı Ekle'"
              @click="handlerAcilisKart(props.row)"
            >
              <i class="fad fa-layer-group" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="warning"
              v-b-tooltip.hover.top="'Güncelle'"
              :to="{
                name: 'urun-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button
              variant="danger"
              class="rounded-0"
              v-b-tooltip.hover.top="'Sil'"
              @click="handlerRemove(props.row.k_no)"
            >
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <UrunTopluGuncelle
      :updateData="updateData"
      :openModal="openModal"
      @closeModal="closeModal = $event"
    />
    <UrunAcilisKarti
      :updateData="updateAkData"
      :urun="urun"
      :openModal="openAkModal"
      @closeModal="closeAkModal = $event"
    />
  </div>
</template>
<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { VueGoodTable } from "vue-good-table";
import UrunAcilisKarti from "./UrunAcilisKarti.vue";
import UrunTopluGuncelle from "./UrunTopluGuncelle.vue";
import { useToast } from "vue-toastification/composition";
import {
  defineComponent,
  ref,
  computed,
  watch,
  toRefs,
} from "@vue/composition-api";
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    UrunTopluGuncelle,
    UrunAcilisKarti,
  },
  props: {
    urunler: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.openAkModal = ref(false);
    expo.closeAkModal = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.multiSelectRow = ref([]);
    expo.updateData = ref([]);
    expo.updateAkData = ref({});
    expo.urun = ref({});

    expo.columns = ref([
      {
        label: "Ürün Bilgisi",
        field: "baslik",
      },
      {
        label: "Diller",
        field: "dil",
        width: "8%",
        thClass: "text-right",
        thClass: "text-right",
        hidden: expo.diller.value.length < 2 && true,
      },
      {
        label: "Kategori",
        field: "kategori",
        width: "12%",
      },
      {
        label: "Fiyat",
        field: "fiyat",
        width: "12%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Stok",
        field: "stok",
        width: "8%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Durum",
        field: "statu",
        width: "5%",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        label: "Vitrin",
        field: "vitrin",
        width: "10%",
        tdClass: "text-right",
      },
      {
        label: "İşlemler",
        field: "action",
        width: "10%",
      },
    ]);

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = event.selectedRows;
    };

    expo.handlerTopluSil = () => {
      let kno = [];
      expo.multiSelectRow.value.forEach((element) => {
        kno.push(element.k_no);
      });

      Swal.fire({
        title: "Uyarı",
        text: `Seçilen ${kno.length} Kayit Silinecektir. Eminmisiniz ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          context.emit("show", true);
          store.dispatch("eTicTopluUrunSil", { k_no: kno }).then((res) => {
            if (res.data.success == true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });
              context.emit("show", false);
            }
          });
        }
      });
    };

    expo.handlerUrunKopyala = () => {
      let k_no = null;
      if (expo.multiSelectRow.value.length > 1) {
        toast.error("Tek ürün seçiniz lütfen.", { position: "bottom-left" });
      } else {
        k_no = expo.multiSelectRow.value[0].k_no;

        Swal.fire({
          title: "Uyarı",
          text: `Seçilen Ürünün kopyasını oluşturmak istediginizden. Eminmisiniz ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        }).then((result) => {
          if (result.isConfirmed == true) {
            context.emit("show", true);
            store.dispatch("eTicUrunKopyala", { k_no }).then((res) => {
              if (res.data.success == true) {
                toast.info("Kopyalama Başarılı", { position: "bottom-left" });
                context.emit("refresh", res.data.data);
                context.emit("show", false);
              }
            });
          }
        });
      }
    };

    expo.handlerTopluGuncelle = () => {
      expo.multiSelectRow.value.forEach((element) => {
        expo.updateData.value.push(element.k_no);
      });
      expo.openModal.value = true;
    };

    expo.vitrinText = computed(() => {
      return (val) => {
        return val == "yeni_urun"
          ? "Yeni Ürün"
          : val == "vitrin_1"
          ? "Vitrin 1"
          : val == "vitrin_2"
          ? "Vitrin 2"
          : val == "firsat_urun"
          ? "Fırsat Ürünü"
          : val == "cok_satan"
          ? "Çok Satan"
          : val == "begenilen"
          ? "Beğenilen"
          : val == "indirimde"
          ? "İndirimde"
          : val == "ozel_urun"
          ? "Özel Ürün"
          : "";
      };
    });

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns
          .post("magaza/urun-statu-guncelle", { k_no, statu: event })
          .then((res) => {
            if (res.data.success === true) {
              toast.success("Güncelleme Başarılı", { position: "bottom-left" });
            }
          });
      }
    };
    expo.handlerAcilisKart = async (event) => {
      expo.urun.value = event;
      expo.openAkModal.value = true;
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch("eTicUrunSil", k_no).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });
            }
          });
        }
      });
    };

    expo.langColor = (icerik, lang) => {
      return icerik[lang].baslik != "" ? "success" : "danger";
    };

    expo.handlerStokDurum = (event) => {
      let giris = 0,
        cikis = 0,
        toplam = 0;

      if (event.length) {
        event.forEach((el) => {
          el.gc_kodu == "G" ? (giris += el.miktar) : (cikis += el.miktar);
        });

        toplam = giris - cikis;
      }

      //burası on taraf on taraf ta ıstedgım ıslemı yaptırabılıyorum benım sorunum back endte bunu yapmak
      return toplam;
    };

    watch([expo.closeModal, expo.closeAkModal], () => {
      expo.openModal.value = false;
      expo.closeModal.value = false;
      expo.openAkModal.value = false;
      expo.closeAkModal.value = false;
      expo.updateData.value = [];
      context.emit("refresh", {});
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
