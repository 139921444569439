<template>
  <div class="product-search">
    <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-input
            size="lg"
            v-model="filter.arama"
            class="rounded-0 mr-1"
            placeholder="Arama Kriteri..."
          />
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.kategori_k_no"
            :options="kategoriler"
            :reduce="(kategori) => kategori.k_no"
            label="title"
            :clearable="true"
            multiple
            placeholder="Kategoriler..."
            class="invoice-filter-select d-block select-size-lg"
          >
            <template slot="selected-option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
            <template slot="option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.marka_k_no"
            :options="markalar"
            :reduce="(marka) => marka.k_no"
            label="title"
            :clearable="true"
            placeholder="Markalar..."
            class="invoice-filter-select d-block select-size-lg"
          >
            <template slot="selected-option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
            <template slot="option" slot-scope="option">
              {{ option.icerik[defaultDil].baslik }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            v-model="filter.siralama"
            :options="siralamalar"
            :reduce="(durum) => durum.id"
            label="title"
            :clearable="false"
            placeholder="Siralama..."
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-col>
        <b-col cols="12" md="4">
          <b-row class="p-0 m-0">
            <b-col class="p-0">
              <v-select
                v-model="filter.statu"
                :options="statuler"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="true"
                placeholder="Durum..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
            <b-col class="p-0">
              <v-select
                v-model="filter.satis_durum"
                :options="satisDurum"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="true"
                placeholder="Satış..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <v-select
            multiple
            v-model="filter.vitrin_gosterim"
            :options="vitrin"
            :reduce="(durum) => durum.id"
            label="title"
            :clearable="true"
            placeholder="Vitrin..."
            class="invoice-filter-select d-block select-size-lg"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-button
            type="submit"
            block
            variant="info"
            size="lg"
            class="rounded-0"
          >
            <i class="fad fa-filter"></i> FİLTRE UYGULA
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button
            block
            variant="danger"
            size="lg"
            class="rounded-0"
            @click="$emit('filtered', false)"
          >
            <i class="fad fa-window-close"></i> TEMİZLE
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import store from "@/store";
import vSelect from "vue-select";
import { defineComponent, computed, ref } from "@vue/composition-api";
export default defineComponent({
  components: { vSelect },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kategoriler = computed(() => store.getters.getTumKategoriler);
    expo.markalar = computed(() => store.getters.getMarkalar);

    expo.siralamalar = ref([
      { id: "urun_kodu_az", title: "Ürün Kodu ( A-Z )" },
      { id: "urun_kodu_za", title: "Ürün Kodu ( Z-A )" },
      { id: "urun_adi_az", title: "Ürün Adı ( A-Z )" },
      { id: "urun_adi_za", title: "Ürün Adı ( Z-A )" },
      { id: "fiyat_az", title: "Fiyata Göre ( A-Z )" },
      { id: "fiyat_za", title: "Fiyata Göre ( Z-A )" },
      { id: "stok_az", title: "Stok Miktarına Göre ( A-Z )" },
      { id: "stok_za", title: "Stok Miktarına Göre ( Z-A )" },
      { id: "once_eklenen", title: "Önce Eklenen" },
      { id: "sonra_eklenen", title: "Sonra Eklenen" },
    ]);

    expo.statuler = ref([
      { id: "yayinda", title: "Yayında" },
      { id: "yayin_disi", title: "Yayın Dışı" },
      { id: "taslak", title: "Taslak" },
    ]);

    expo.satisDurum = ref([
      { id: true, title: "Satışa Açık" },
      { id: false, title: "Satışa Kapalı" },
    ]);

    expo.vitrin = ref([
      { id: "yeni_urun", title: "Yeni Ürün" },
      { id: "firsat_urun", title: "Fırsat Ürünü" },
      { id: "cok_satan", title: "Çok Satan" },
      { id: "begenilen", title: "Beğenilen" },
      { id: "indirimde", title: "İndirimde" },
      { id: "ozel_urun", title: "Özel Ürün" },
    ]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
