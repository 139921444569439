<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="lg"
      class="rounded-0"
      centered
      title="Toplu Ürün Güncelle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group label-cols="5" label=" Toplam Seçilen Ürün : ">
              <h6 class="pt-2">{{ updateData.length }} Adet</h6>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-cols="5" label="Kategoriler">
              <v-select
                v-model="form.kategori_k_no"
                :options="kategoriler"
                :reduce="(kategori) => kategori.k_no"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg"
              >
                <template slot="selected-option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-cols="5" label="Marka">
              <v-select
                v-model="form.marka_k_no"
                :options="markalar"
                :reduce="(marka) => marka.k_no"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg"
              >
                <template slot="selected-option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-cols="5" label="Yayın Durumu">
              <v-select
                v-model="form.statu"
                :options="statuler"
                :reduce="(statu) => statu.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-cols="5" label="Vitrin Gösterim">
              <v-select
                multiple
                v-model="form.vitrin_gosterim"
                :options="vitrin"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-3">
              <label for="statu">Ücretsiz Kargo</label>
              <b-form-checkbox :checked="form.ucretsiz_kargo" v-model="form.ucretsiz_kargo" switch size="lg" />
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols="5"
              label="Kargo Desi"
              v-b-tooltip.hover="'Kargo Desi Girildiği Zaman Hesaplama Kargo Firmasına Girilmiş Desi Fiyatına Göre Hesaplanır'"
            >
              <b-form-input
                size="lg"
                type="number"
                class="rounded-0 text-right"
                ref="baslik"
                v-model="form.kargo_desi"
                :disabled="form.ucretsiz_kargo"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols="5"
              label="Kargo Teslimat Süresi"
              v-b-tooltip.hover="'Kargo Ortalama Teslimat Süresi Gün Cinsinden giriniz'"
            >
              <b-form-input size="lg" type="number" class="rounded-0 text-right" ref="baslik" v-model="form.kargo_suresi" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center py-3">
              <label for="statu">Satışa {{ form.satis_durum ? 'Açık' : 'Kapalı' }}</label>
              <b-form-checkbox :checked="form.satis_durum" v-model="form.satis_durum" switch size="lg" />
            </div>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button size="lg" squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Güncelle
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';

export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Array,
      required: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kategoriler = computed(() => store.getters.getKategoriler);
    expo.markalar = computed(() => store.getters.getMarkalar);
    expo.show = ref(false);

    expo.statuler = ref([
      { id: 'yayinda', title: 'Yayında' },
      { id: 'yayin_disi', title: 'Yayın Dışı' },
      { id: 'taslak', title: 'Taslak' },
    ]);

    expo.vitrin = ref([
      { id: 'vitrin_1', title: 'Vitrin 1' },
      { id: 'vitrin_2', title: 'Vitrin 2' },
      { id: 'yeni_urun', title: 'Yeni Ürün' },
      { id: 'firsat_urun', title: 'Fırsat Ürünü' },
      { id: 'cok_satan', title: 'Çok Satan' },
      { id: 'begenilen', title: 'Beğenilen' },
      { id: 'indirimde', title: 'İndirimde' },
      { id: 'ozel_urun', title: 'Özel Ürün' },
    ]);

    const { updateData } = toRefs(props);

    expo.form = ref({
      k_no: [],
      kategori_k_no: null,
      marka_k_no: null,
      statu: null,
      vitrin_gosterim: [],
      ucretsiz_kargo: false,
      kargo_desi: 0,
      kargo_suresi: 0,
    });

    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };
    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = () => {
      expo.show.value = true;

      expo.form.value.k_no = updateData.value;
      store
        .dispatch('eTicTopluUrunGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            expo.show.value = false;
            expo.form.value = {
              k_no: [],
              kategori_k_no: null,
              marka_k_no: null,
              statu: null,
              vitrin_gosterim: [],
              ucretsiz_kargo: false,
              kargo_desi: 0,
              kargo_suresi: 0,
            };
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };
    const prop = toRefs(props);

    watch(
      prop.openModal,
      (val) => {
        if (val == true) {
          expo.showModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
