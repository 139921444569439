<template>
  <div>
    <b-row>
      <b-col>
        <div>
          <input
            multiple
            type="file"
            ref="file"
            name="logo"
            id="selectorImagesInput"
            accept="image/jpg,image/jpeg,image/png,image/gif"
            class="selector-images-input"
            @change="handlerOnSumbited"
          />
          <div class="selector-images-button" :disabled="true" @click="handlerSelectedLogoImages">
            <i class="fad fa-file-plus fa-3x" v-if="!show" />
            <b-spinner v-else />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!gorseller.length">
      <b-col>
        <b-alert variant="warning" show class="text-center">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Henüz Kayıt Bulunmamaktadır.</p>
          <p>Çoklu Resim yüklemeler de bazı resimler görünmeye bilir sayfayı yenileyiniz lütfen.</p>
        </b-alert>
      </b-col>
    </b-row>

    <draggable v-else v-model="gorseller" tag="div" class="row" handle=".draggable-task-handle" @end="handlerGorselSiraChange">
      <b-col cols="12" md="4" lg="3" sm="6" v-for="(item, index) in gorseller" :key="index">
        <div class="images-show">
          <div class="images-action">
            <span @click="handlerRemove(item)">
              <i class="fad fa-trash" v-b-tooltip.hover.top="'Sil'" />
            </span>
            <span :class="{ active: item.kapak }" @click="handlerGorselKapakChange(item.k_no)">
              <i class="fad fa-tv" v-b-tooltip.hover.top="'Kapak Resmi Yap'" />
            </span>
          </div>
          <b-img-lazy v-bind="mainProps" class="draggable-task-handle m-1" :src="handlerImageShow(item.gorsel)" />
          <span class="title">{{ item.gorsel }}</span>
        </div>
      </b-col>
    </draggable>
  </div>
</template>

<script>
import store from '@/store';
import draggable from 'vuedraggable';
import { defineComponent, computed, ref, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: {
    draggable,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.gorseller = ref([]);
    const { form } = toRefs(props);

    expo.mainProps = ref({
      center: true,
      fluidGrow: true,
      blank: true,
      blankColor: '#bbb',
      thumbnail: true,
      show: true,
    });

    expo.handlerImageShow = (val) => {
      if (val != undefined) {
        const fileName = store.getters.getUserFirma.db;
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

        const url = `${base_url}uploads/${fileName}/urun/${val}`;
        return url;
      }
    };

    expo.handlerSelectedLogoImages = () => {
      const file = document.getElementById('selectorImagesInput');
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;
      Object.keys(forms).map(function (key) {
        formData.append('urun', forms[key]);
      });

      formData.append('k_no', form.value.k_no);

      await store
        .dispatch('eTicUrunGorselEkle', formData)
        .then(async (res) => {
          expo.show.value = false;
          context.emit('form', res.data.data);
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerRemove = (item) => {
      const data = {
        sayfa_k_no: form.value.k_no,
        img_k_no: item.k_no,
        gorsel: item.gorsel,
      };
      store.dispatch('eTicUrunGorselSil', data).then((res) => {
        if (res.data.success == true) {
          toast.error('Görsel Silme Başarılı.', { position: 'bottom-left' });
          expo.gorseller.value = res.data.data.gorseller;
        }
      });
    };

    expo.handlerGorselSiraChange = () => {
      store
        .dispatch('eTicUrunGorselSiraGuncelle', { k_no: form.value.k_no, gorseller: expo.gorseller.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Görsel sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    expo.handlerGorselKapakChange = (k_no) => {
      store
        .dispatch('eTicUrunGorselKapakGuncelle', { sayfa_k_no: form.value.k_no, img_k_no: k_no })
        .then((res) => {
          if (res.data.success == true) {
            expo.gorseller.value = res.data.data.gorseller;
            toast.success('Görsel kapak güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    watch(
      form,
      (val) => {
        if (Object.keys(val).length) {
          expo.gorseller.value = val.gorseller;
        }
      },
      {
        immediate: true,
      }
    );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.images-show {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: move;
  .images-action {
    position: absolute;
    right: 0px;
    top: 20px;
    span {
      opacity: 0;
      visibility: hidden;
      box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      background: #ddd;
      padding: 5px;
      margin-right: 10px;
      cursor: pointer;
      .fa-trash {
        color: red;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        color: green;
      }
    }
  }
  span.title {
    position: absolute;
    bottom: 13px;
    left: 5px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    background: linear-gradient(270deg, rgba(244, 244, 244, 0.1) 0%, rgba(222, 222, 222, 0.7) 100%);
    color: #fff;
  }

  &:hover {
    .images-action {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
